import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ClipLoader } from "react-spinners";

const AddPerson = ({ notify }) => {
  const navigate = useNavigate();
  const { SidangId } = useParams();
  const [sidang, setSidang] = useState(null);
  const [name, setName] = useState("");
  const [family, setFamily] = useState("");
  const [kepel, setKepel] = useState("");
  const [nik, setNik] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [birthplace, setBirthplace] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [blood, setBlood] = useState("");
  const [occupation, setOccupation] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/");
    }
    fetch();
  }, []);

  async function fetch() {
    try {
      const response = await axios({
        method: "GET",
        url: `sidang/name/${SidangId}`,
        headers: {
          access_token: localStorage.getItem("access_token"),
        },
      });

      console.log(response);

      setSidang(response.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      if (err.response.data.message === "Token expired") {
        notify("Masa login habis, silahkan login kembali", "error");
        localStorage.clear();
        navigate("/");
      }
      console.log(err);
      setLoading(false);
    }
  }

  async function submit(e) {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      const response = await axios({
        method: "POST",
        url: "person",
        headers: {
          access_token: localStorage.getItem("access_token"),
        },
        data: {
          name,
          family,
          kepel,
          nik,
          birthdate,
          birthplace,
          gender,
          address,
          blood,
          occupation,
          phone,
          email,
          SidangId,
        },
      });
      setSubmitLoading(false);
      notify("Berhasil menambahkan data", "success");
      navigate("/sidang/" + SidangId);
    } catch (err) {
      if (err.response.data.message === "Token expired") {
        notify("Masa login habis, silahkan login kembali", "error");
        localStorage.clear();
        navigate("/");
      }
      notify("Gagal menambahkan data", "error");
      setSubmitLoading(false);
      console.log(err);
    }
  }
  return (
    <div className="max-w-screen lg:w-[900px] min-h-screen mx-auto px-3 lg:px-0">
      <div className="my-3">
        <p className="text-2xl font-bold">TAMBAH DATA</p>
        {loading ? (
          <SkeletonTheme>
            <Skeleton className="h-10" />
          </SkeletonTheme>
        ) : (
          <p className="text-2xl font-bold">
            <span className="text-red-500">KGPM</span> {sidang.name}
          </p>
        )}
      </div>
      <div className="w-full mx-auto mt-3">
        <form
          action="submit"
          className="border-2 rounded-md px-8 py-6 shadow-md"
          onSubmit={(e) => submit(e)}
        >
          <div className="mt-3">
            <p>
              <span className={`font-semibold ${name ? "" : "text-pink-600"}`}>
                Nama
              </span>
            </p>
            <input
              type="text"
              name=""
              id=""
              className="w-full border-b-2 focus-within:outline-none py-3"
              onChange={(e) => setName(e.target.value)}
              disabled={submitLoading}
            />
          </div>
          <div className="flex w-full mt-3 gap-6">
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${family ? "" : "text-pink-600"}`}
                >
                  Nama Keluarga
                </span>
              </p>
              <input
                type="text"
                name=""
                id=""
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(e) => setFamily(e.target.value)}
                disabled={submitLoading}
              />
            </div>
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${gender ? "" : "text-pink-600"}`}
                >
                  Jenis Kelamin
                </span>
              </p>
              <select
                name=""
                id=""
                className="w-full border-2 py-2 mt-2 text-center rounded"
                onChange={(e) => setGender(e.target.value)}
                disabled={submitLoading}
              >
                <option value="" className="text-slate-200" selected disabled>
                  Pilih Jenis Kelamin
                </option>
                <option value="L" className="text-slate-200">
                  Laki - laki
                </option>
                <option value="P" className="text-slate-200">
                  Perempuan
                </option>
              </select>
            </div>
          </div>
          <div className="flex w-full mt-3 gap-6">
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${kepel ? "" : "text-pink-600"}`}
                >
                  Kepel
                </span>
              </p>
              <input
                type="text"
                name=""
                id=""
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(e) => setKepel(e.target.value)}
                disabled={submitLoading}
              />
            </div>
            <div className="w-1/2">
              <p>
                <span className={`font-semibold ${nik ? "" : "text-pink-600"}`}>
                  NIK
                </span>
              </p>
              <input
                type="text"
                name=""
                id=""
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(e) => setNik(e.target.value)}
                disabled={submitLoading}
              />
            </div>
          </div>
          <div className="flex w-full mt-3 gap-6">
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${
                    birthplace ? "" : "text-pink-600"
                  }`}
                >
                  Tempat Lahir
                </span>
              </p>
              <input
                type="text"
                name=""
                id=""
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(e) => setBirthplace(e.target.value)}
                disabled={submitLoading}
              />
            </div>
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${
                    birthdate ? "" : "text-pink-600"
                  }`}
                >
                  Tanggal Lahir
                </span>
                <span>(Format: DD-MM-YYYY)</span>
              </p>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(date) => setBirthdate(date)}
                selected={birthdate}
                disabled={submitLoading}
              />
            </div>
          </div>

          <div className="flex w-full mt-3 gap-6">
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${blood ? "" : "text-pink-600"}`}
                >
                  Golongan Darah
                </span>
              </p>
              <input
                type="text"
                name=""
                id=""
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(e) => setBlood(e.target.value)}
                disabled={submitLoading}
              />
            </div>
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${
                    occupation ? "" : "text-pink-600"
                  }`}
                >
                  Pekerjaan
                </span>
              </p>
              <input
                type="text"
                name=""
                id=""
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(e) => setOccupation(e.target.value)}
                disabled={submitLoading}
              />
            </div>
          </div>
          <div className="flex w-full mt-3 gap-6">
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${phone ? "" : "text-pink-600"}`}
                >
                  Nomor Telepon
                </span>
              </p>
              <input
                type="text"
                name=""
                id=""
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(e) => setPhone(e.target.value)}
                disabled={submitLoading}
              />
            </div>
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${email ? "" : "text-pink-600"}`}
                >
                  Email
                </span>
              </p>
              <input
                type="text"
                name=""
                id=""
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(e) => setEmail(e.target.value)}
                disabled={submitLoading}
              />
            </div>
          </div>
          <div className="mt-3">
            <p>
              <span
                className={`font-semibold ${address ? "" : "text-pink-600"}`}
              >
                Alamat
              </span>
            </p>
            <textarea
              rows={4}
              className="w-full border-2 focus-within:outline-none p-1 mt-1"
              onChange={(e) => setAddress(e.target.value)}
              disabled={submitLoading}
            />
          </div>
          <div className="mt-3">
            {birthdate
              ? ` Tanggal lahir: ${birthdate.toLocaleDateString("id-ID", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}`
              : ""}
          </div>

          <div className="flex justify-center gap-3 mt-6 text-white text-sm font-semibold">
            {submitLoading || loading ? (
              <ClipLoader color="#36d7b7" />
            ) : (
              <>
                {" "}
                <button
                  className="bg-red-500 px-5 py-1 rounded-full"
                  onClick={() => navigate("/sidang/" + SidangId)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`px-5 py-1 rounded-full ${
                    !name ||
                    !family ||
                    !kepel ||
                    !nik ||
                    !birthdate ||
                    !birthplace ||
                    !gender ||
                    !address ||
                    !blood ||
                    !occupation ||
                    !phone ||
                    !email
                      ? "bg-slate-500"
                      : "bg-green-500"
                  }`}
                  disabled={
                    !name ||
                    !family ||
                    !kepel ||
                    !nik ||
                    !birthdate ||
                    !birthplace ||
                    !gender ||
                    !address ||
                    !blood ||
                    !occupation ||
                    !phone ||
                    !email
                  }
                >
                  Submit
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPerson;
