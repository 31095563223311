import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ClipLoader } from "react-spinners";

const EditSidang = ({ notify }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [newName, setNewName] = useState("");
  const [WilayahId, setWilayahId] = useState("");
  const [area, setArea] = useState("");
  const [bps, setBps] = useState("");
  const [pms, setPms] = useState("");
  const [kontak, setKontak] = useState("");
  const [prevName, setPrevName] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const arr = [];
  for (let index = 1; index <= 34; index++) {
    arr.push(index);
  }

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/");
    }
    fetch();
  }, []);

  async function fetch() {
    try {
      const response = await axios({
        method: "GET",
        url: `sidang/name/${id}`,
        headers: {
          access_token: localStorage.getItem("access_token"),
        },
      });

      setName(response.data.name);
      setPrevName(response.data.name);
      setWilayahId(response.data.WilayahId);
      setArea(response.data.area);
      setBps(response.data.bps);
      setPms(response.data.pms);
      setKontak(response.data.kontak);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      if (err.response.data.message === "Token expired") {
        notify("Masa login habis, silahkan login kembali", "error");
        localStorage.clear();
        navigate("/");
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }

  async function submit(e) {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      await axios({
        method: "PUT",
        url: `sidang/${id}`,
        headers: {
          access_token: localStorage.getItem("access_token"),
        },
        data: {
          name,
          WilayahId,
          area,
          bps,
          pms,
          kontak
        },
      });
      setSubmitLoading(false);
      notify("Berhasil update sidang", "success");
      navigate("/dashboard");
    } catch (err) {
      if (err.response.data.message === "Token expired") {
        notify("Masa login habis, silahkan login kembali", "error");
        localStorage.clear();
        navigate("/");
      }
      console.log(err);
      setSubmitLoading(false);

      if (err.response.data.message === "SequelizeUniqueConstraintError") {
        notify("Nama sidang sudah ada", "error");
      }
    }
  }
  return (
    <div className="max-w-screen lg:w-[900px] min-h-screen mx-auto">
      <div className="my-3">
        {loading ? (
          <SkeletonTheme>
            <Skeleton />
          </SkeletonTheme>
        ) : (
          <p className="text-2xl font-bold">
            EDIT SIDANG <span className="text-red-500">{prevName} </span>
          </p>
        )}
      </div>
      <div className="w-3/4 mx-auto mt-3">
        {loading ? (
          <div className="border-2 rounded-md px-8 py-6 shadow-md animate-pulse">
            {" "}
            <SkeletonTheme>
              <Skeleton className="my-6" />
              <Skeleton className="my-6" />
              <Skeleton className="my-6" />
              <Skeleton className="my-6" />
              <Skeleton className="my-6" />
              <Skeleton className="my-6" />
            </SkeletonTheme>
          </div>
        ) : (
          <form
            action="submit"
            className="border-2 rounded-md px-8 py-6 shadow-md"
            onSubmit={(e) => submit(e)}
          >
            <div>
              <p>
                <span
                  className={`font-semibold ${name ? "" : "text-pink-600"}`}
                >
                  Nama Sidang
                </span>
                <span className="text-sm italic font-semibold">
                  {" (tidak perlu menuliskan KGPM di depan)"}
                </span>
              </p>
              <input
                type="text"
                name=""
                id=""
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(e) => setName(e.target.value)}
                value={name}
                disabled={submitLoading}
              />
            </div>
            <div className="w-full flex gap-5 mt-6">
              <div className="w-1/2">
                <p
                  className={`font-semibold ${
                    WilayahId ? "" : "text-pink-600"
                  }`}
                >
                  Wilayah
                </p>
                <select
                  name=""
                  id=""
                  className="w-full border-2 py-2 mt-2 text-center rounded"
                  onChange={(e) => setWilayahId(e.target.value)}
                  value={WilayahId}
                  disabled={submitLoading}
                >
                  <option value="" className="text-slate-200" disabled>
                    Pilih wilayah
                  </option>
                  {arr.map((e) => {
                    return <option value={e}>{e} </option>;
                  })}
                </select>
              </div>
              <div className="w-1/2">
                <p className={`font-semibold ${area ? "" : "text-pink-600"}`}>
                  Kota/Kabupaten
                </p>
                <input
                  type="text"
                  name=""
                  id=""
                  className="w-full border-b-2 focus-within:outline-none py-3"
                  onChange={(e) => setArea(e.target.value)}
                  value={area}
                  disabled={submitLoading}
                />
              </div>
            </div>
            <div className="mt-6">
              <p className={`font-semibold ${bps ? "" : "text-pink-600"}`}>
                Nama Ketua BPS
              </p>
              <input
                type="text"
                name=""
                id=""
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(e) => setBps(e.target.value)}
                value={bps}
                disabled={submitLoading}
              />
            </div>
            <div className="mt-6">
              <p className={`font-semibold ${pms ? "" : "text-pink-600"}`}>
                Nama Ketua PMS
              </p>
              <input
                type="text"
                name=""
                id=""
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(e) => setPms(e.target.value)}
                value={pms}
                disabled={submitLoading}
              />
            </div>
            <div className="mt-6">
              <p className={`font-semibold ${kontak ? "" : "text-pink-600"}`}>
                Kontak
              </p>
              <input
                type="text"
                name=""
                id=""
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(e) => setKontak(e.target.value)}
                value={kontak}
                disabled={submitLoading}
              />
            </div>
            <div className="flex justify-center gap-3 mt-6 text-white text-sm font-semibold">
              {submitLoading ? (
                <ClipLoader color="#36d7b7" />
              ) : (
                <>
                  {" "}
                  <button
                    className="bg-red-500 px-5 py-1 rounded-full"
                    onClick={() => navigate("/sidang/" + id)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={!name || !WilayahId || !area || !pms || !bps}
                    className={`px-5 py-1 rounded-full ${
                      !name || !WilayahId || !area || !pms || !bps
                        ? "bg-slate-500"
                        : "bg-green-500"
                    }`}
                  >
                    Submit
                  </button>
                </>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditSidang;
