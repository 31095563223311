import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ReactPaginate from "react-paginate";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";

const Dashboard = ({ notify }) => {
  const navigate = useNavigate();
  const [sidang, setSidang] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(
    localStorage.getItem("dashboardLimit") || 10
  );
  const [pages, setPages] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [totalSidang, setTotalSidang] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [firstLoading, setFirstLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/");
    }

    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [page, keyword, limit]);

  async function fetchData() {
    try {
      const response = await axios({
        method: "GET",
        url: `sidang?search=${keyword}&page=${page}&limit=${limit}}`,
        headers: {
          access_token: localStorage.getItem("access_token"),
        },
      });

      setSidang(response.data.sidang);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setTotalSidang(response.data.total);
      setTotalData(response.data.totalData);

      console.log(response.data);
      setTimeout(() => {
        setFirstLoading(false);
        setLoading(false);
      }, 1000);
    } catch (err) {
      if (err.response.data.message === "Token expired") {
        notify("Masa login habis, silahkan login kembali", "error");
        localStorage.clear();
        navigate("/");
      }
      console.log(err);
      setTimeout(() => {
        setFirstLoading(false);
        setLoading(false);
      }, 1000);
    }
  }

  function changePage({ selected }) {
    setPage(selected);
  }

  function changeLimit(value) {
    localStorage.setItem("dashboardLimit", value);
    setLimit(value);
  }

  function changeKeyword(value) {
    setPage(0)
    setKeyword(value)
  }

  const cards = () => {
    return (
      <>
        {sidang.map((e, i) => {
          return (
            <div
              className={`w-full p-3 flex  items-center border-x-2 hover:bg-slate-300 hover:border-l-slate-600  cursor-pointer gap-3 transition-all duration-150 ${
                i % 2 !== 0 ? "bg-slate-100" : ""
              }`}
              onClick={() => navigate(`/sidang/${e.id}`)}
              key={e.id}
            >
              <p className="w-[5%] flex justify-center">{(page) * limit + (i+1) }</p>
              <p className="w-1/12 md:w-1/12 flex justify-center">{e.id}</p>
              <p className="w-1/3 md:w-1/3">
                <span className="text-red-500 font-semibold">KGPM</span>{" "}
                {e.name}
              </p>
              <p className="w-1/3 md:w-1/4 flex items-center justify-center md:justify-center">
                {e.WilayahId}
              </p>
              <p className="w-1/3 md:w-[15%] items-center hidden md:flex">
                {e.area}
              </p>
              <div className="w-1/3 md:w-1/4 flex gap-1 items-center justify-center">
                {e.People.length}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const cardSkeleton = () => {
    return (
      <>
        <SkeletonTheme baseColor="#0000" highlightColor="#ffff">
          {Array(Number(limit))
            .fill()
            .map((e, i) => {
              return (
                <div
                  className={`w-full animate-pulse  p-3 flex items-center border-x-2  gap-3 ${
                    i % 2 !== 1 ? "bg-white" : "bg-slate-100"
                  }`}
                  key={i}
                >
                  <Skeleton />
                </div>
              );
            })}
        </SkeletonTheme>
      </>
    );
  };

  const totalSkeleton = () => {
    return (
      <>
        <SkeletonTheme baseColor="#0000" highlightColor="#ffff">
          <div className="mt-3 flex justify-between items-center bg-white">
            <Skeleton />
            <Skeleton />
          </div>
        </SkeletonTheme>
      </>
    );
  };

  const total = () => {
    return (
      <>
        <div className="mt-3 flex justify-between items-center">
          <p>Jumlah Sidang: {totalSidang}</p>
          <p>Jumlah data: {totalData}</p>
        </div>
      </>
    );
  };

  if (firstLoading) {
    return <Loading showLoading={firstLoading} />;
  }

  return (
    <div className="max-w-screen w-full lg:w-[1000px] xl:w-[1200px] min-h-screen mx-auto px-3 lg:px-0 mb-5">
      <div className="w-full mt-3 md:mt-10">
        <input
          type="text"
          name=""
          id=""
          className="appearance-none w-full py-2 border-b-2 duration-150 focus-within:border-slate-700 transition-all placeholder:italic"
          placeholder="Cari nama sidang/wilayah/kota&kabupaten "
          onChange={(e) => changeKeyword(e.target.value)}
        />
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center mt-3">
        <div className=" w-full md:py-1 items-center justify-center md:justify-start gap-3 py-2 ">
          <div className="flex gap-3">
            <p>Data per halaman</p>
            <select
              name=""
              id=""
              value={limit}
              onChange={(e) => changeLimit(e.target.value)}
              className="border-2 px-1 rounded-lg focus-within:outline-none"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>
        </div>
        <div className="w-full md:flex justify-end  items-center">
          <button
            className="px-6 md:px-2 md:py-1 w-full py-2 border-2 text-sm rounded-full border-slate-700 hover:bg-slate-700 hover:text-white transition-all duration-150 md:w-1/2 lg:w-1/3 block"
            onClick={() => navigate("/sidang/add")}
          >
            + Tambah sidang
          </button>
        </div>
      </div>
      <div className="w-full flex p-3 mt-3 items-center gap-3 border-t-2 border-l-2 border-r-2 rounded-t-lg overflow-hidden bg-slate-700 border-slate-700 shadow-sm text-white">
        <p className="w-[5%] flex justify-center">No</p>
        <p className="w-1/12 md:w-1/12 flex justify-center">ID</p>
        <p className="w-1/3 md:w-1/3 pl-1">Nama sidang</p>
        <p className="w-1/3 flex justify-center md:w-1/4 ">Wilayah</p>
        <p className="w-1/3 md:w-[15%] hidden md:inline">Kota/Kabupaten</p>
        <p className="w-1/3 md:w-1/4 flex justify-center text-center">
          Jumlah data
        </p>
      </div>
      <div className="shadow-sm border-b-2 border-slate-200 rounded-b-lg overflow-hidden">
        {loading ? cardSkeleton() : cards()}
      </div>

      {loading ? totalSkeleton() : total()}
      {totalSidang > limit ? (
        <div className="flex mt-5 justify-center items-center max-h-screen">
          <ReactPaginate
            previousLabel={<CgArrowLongLeft />}
            nextLabel={<CgArrowLongRight />}
            pageCount={pages}
            className="flex md:gap-3 items-center justify-center"
            onPageChange={changePage}
            containerClassName=""
            pageLinkClassName="border-2 p-1 rounded rounded-full px-2 hover:bg-black hover:text-white transition-all duration-150"
            activeLinkClassName="bg-black text-white"
            nextClassName="border-2 py-2 px-2 rounded hidden md:block rounded-md px-3 hover:bg-black hover:text-white transition-all duration-150"
            previousClassName="border-2 py-2 rounded hidden md:block rounded-md px-3 hover:bg-black hover:text-white transition-all duration-150"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Dashboard;
