import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const Navbar = () => {
  const navigate = useNavigate();

  function logout() {
    localStorage.clear();
    navigate("/");
  }

  if (localStorage.getItem("access_token")) {
    return (
      <div className="w-full h-16 flex items-center py-3 px-4 md:px-6 bg-slate-700 justify-between text-white z-0">
        <div className="flex h-full items-center w-full">
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => navigate("/dashboard")}
          >
            <img
              src="https://d1fdloi71mui9q.cloudfront.net/dw73EeRsQzGAXL9vACRR_-1080p-%20LOGO%20BARU%20PEMUDA%20KGPM.png"
              alt="logo"
              className="h-10 aspect-square"
            />
            <p className="font-thin inline-block text-white">
              DATABASE{" "}
              <span className="font-normal text-white hidden md:inline">
                PEMUDA KGPM
              </span>
            </p>
          </div>
        </div>
        <div>
          <button
            className="bg-red-500 px-5 py-1 text-sm rounded-full"
            onClick={() => logout()}
          >
            Logout
          </button>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Navbar;
