import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {ScaleLoader} from "react-spinners/";

const Loading = ({ showLoading }) => {
  if (!showLoading) {
    return null;
  }

  return (
    <>
    <div className="h-screen w-screen"></div>
    <div className="h-screen w-screen fixed inset-0 bg-slate-100 bg-opacity-10 flex justify-center items-center z-50">
      <div className="flex justify-center items-center gap-3">
        <ScaleLoader color="#334155" height={20}  /> <p className="text-xl lg:text-base">DATABASE <span className="font-bold">PEMUDA KGPM</span> </p>
      </div>
    </div>
    </>
  );
};

export default Loading;
