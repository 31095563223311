import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ClipLoader } from "react-spinners";

const EditPerson = ({ notify }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [sidang, setSidang] = useState(null);
  const [name, setName] = useState("");
  const [prevName, setPrevName] = useState("");
  const [family, setFamily] = useState("");
  const [kepel, setKepel] = useState("");
  const [nik, setNik] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [birthplace, setBirthplace] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [blood, setBlood] = useState("");
  const [occupation, setOccupation] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [SidangId, setSidangId] = useState("");
  const [names, setNames] = useState(null);
  const [uniqueName, setUniqueName] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/");
    }
    fetch();
  }, []);

  async function fetch() {
    try {
      const response = await axios({
        method: "GET",
        url: "person/" + id,
        headers: {
          access_token: localStorage.getItem("access_token"),
        },
      });

      const sidangNames = await axios({
        method: "GET",
        url: "sidang/name",
        headers: {
          access_token: localStorage.getItem("access_token"),
        },
      });

      setNames(sidangNames.data);

      const d = response.data;

      setSidangId(d.SidangId);
      setSidang(d.Sidang);
      setName(d.name);
      setPrevName(d.name);
      setFamily(d.family);
      setKepel(d.kepel);
      setNik(d.nik);

      let flag = new Date(d.birthdate);

      console.log(flag);

      if (flag == "Invalid Date") {
      } else {
        setBirthdate(new Date(d.birthdate));
      }
      // if(new Date(d.birthdate) !== 'Invalid Date'){

      // } else {
      //   setBirthdate = ""
      // }

      setBirthplace(d.birthplace);
      setGender(d.gender);
      setAddress(d.address);
      setBlood(d.blood);
      setOccupation(d.occupation);
      setPhone(d.phone);
      setEmail(d.email);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      if (err.response.data.message === "Token expired") {
        notify("Masa login habis, silahkan login kembali", "error");
        localStorage.clear();
        navigate("/");
      }
      console.log(err);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }

  async function submit(e) {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      const response = await axios({
        method: "PUT",
        url: "person/" + id,
        headers: {
          access_token: localStorage.getItem("access_token"),
        },
        data: {
          name,
          family,
          kepel,
          nik,
          birthdate,
          birthplace,
          gender: gender.toLocaleUpperCase(),
          address,
          blood,
          occupation,
          phone,
          email,
          SidangId,
        },
      });
      setSubmitLoading(false);
      notify("Berhasil update data", "success");

      navigate("/sidang/" + SidangId);
    } catch (err) {
      if (err.response.data.message === "Token expired") {
        notify("Masa login habis, silahkan login kembali", "error");
        localStorage.clear();
        navigate("/");
      }
      console.log(err);
      notify("Gagal update data", "error");
      if (err.response.data.err.errors[0].message === "name must be unique") {
        notify("Nama sudah ada", "error");
      } else {
        setSubmitLoading(false);
      }
    }
  }

  return (
    <div className="max-w-screen lg:w-[900px] min-h-screen mx-auto px-3 lg:px-0">
      <div className="my-3">
        {loading ? (
          <SkeletonTheme>
            <Skeleton className="h-10" />
          </SkeletonTheme>
        ) : (
          <p className="text-2xl font-bold flex gap-8">
            <span>EDIT</span>
            <span className="text-slate-400">{prevName}</span>{" "}
          </p>
        )}

        <div>
          {loading ? (
            <SkeletonTheme>
              <Skeleton className="h-10" />
            </SkeletonTheme>
          ) : (
            <div className="text-2xl font-bold flex gap-3">
              <p className="text-red-500">KGPM</p>
              <select
                name=""
                id=""
                onChange={(e) => setSidangId(e.target.value)}
                disabled={submitLoading}
                value={SidangId}
              >
                {names.map((e) => {
                  return <option value={e.id}>{e.name}</option>;
                })}
              </select>
            </div>
          )}
        </div>
      </div>
      <div className="w-full mx-auto mt-3 mb-10">
        <form
          action="submit"
          className="border-2 rounded-md px-8 py-6 shadow-md"
          onSubmit={(e) => submit(e)}
        >
          <div className="mt-3">
            <p
              className={`font-semibold ${
                name && !uniqueName ? "" : "text-pink-600"
              }`}
            >
              Nama{" "}
              {uniqueName ? (
                <span className="text-sm text-slate-400">
                  {" "}
                  {`${uniqueName}`}
                </span>
              ) : (
                ""
              )}
            </p>
            {loading ? (
              <SkeletonTheme>
                <Skeleton className="w-full border-b-2 focus-within:outline-none py-3" />
              </SkeletonTheme>
            ) : (
              <input
                type="text"
                name=""
                id=""
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(e) => setName(e.target.value)}
                disabled={submitLoading}
                value={name}
              />
            )}
          </div>
          <div className="flex w-full mt-3 gap-6">
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${family ? "" : "text-pink-600"}`}
                >
                  Nama Keluarga
                </span>
              </p>
              {loading ? (
                <SkeletonTheme>
                  <Skeleton className="w-full border-b-2 focus-within:outline-none py-3" />
                </SkeletonTheme>
              ) : (
                <input
                  type="text"
                  name=""
                  id=""
                  className="w-full border-b-2 focus-within:outline-none py-3"
                  onChange={(e) => setFamily(e.target.value)}
                  disabled={submitLoading}
                  value={family}
                />
              )}
            </div>
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${gender ? "" : "text-pink-600"}`}
                >
                  Jenis Kelamin
                </span>
              </p>
              {loading ? (
                <SkeletonTheme>
                  <Skeleton className="w-full border-b-2 focus-within:outline-none py-3" />
                </SkeletonTheme>
              ) : (
                <select
                  name=""
                  id=""
                  className="w-full border-2 py-2 mt-2 text-center rounded"
                  onChange={(e) => setGender(e.target.value)}
                  disabled={submitLoading}
                  value={gender}
                >
                  <option value="" className="text-slate-200" selected disabled>
                    Pilih Jenis Kelamin
                  </option>
                  <option value="L" className="text-slate-200">
                    Laki - laki
                  </option>
                  <option value="P" className="text-slate-200">
                    Perempuan
                  </option>
                </select>
              )}
            </div>
          </div>
          <div className="flex w-full mt-3 gap-6">
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${kepel ? "" : "text-pink-600"}`}
                >
                  Kepel
                </span>
              </p>

              {loading ? (
                <SkeletonTheme>
                  <Skeleton className="w-full border-b-2 focus-within:outline-none py-3" />
                </SkeletonTheme>
              ) : (
                <input
                  type="text"
                  name=""
                  id=""
                  className="w-full border-b-2 focus-within:outline-none py-3"
                  onChange={(e) => setKepel(e.target.value)}
                  disabled={submitLoading}
                  value={kepel}
                />
              )}
            </div>
            <div className="w-1/2">
              <p>
                <span className={`font-semibold ${nik ? "" : "text-pink-600"}`}>
                  NIK
                </span>
              </p>
              {loading ? (
                <SkeletonTheme>
                  <Skeleton className="w-full border-b-2 focus-within:outline-none py-3" />
                </SkeletonTheme>
              ) : (
                <input
                  type="text"
                  name=""
                  id=""
                  className="w-full border-b-2 focus-within:outline-none py-3"
                  onChange={(e) => setNik(e.target.value)}
                  disabled={submitLoading}
                  value={nik}
                />
              )}
            </div>
          </div>
          <div className="flex w-full mt-3 gap-6">
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${
                    birthplace ? "" : "text-pink-600"
                  }`}
                >
                  Tempat Lahir
                </span>
              </p>
              {loading ? (
                <SkeletonTheme>
                  <Skeleton className="w-full border-b-2 focus-within:outline-none py-3" />
                </SkeletonTheme>
              ) : (
                <input
                  type="text"
                  name=""
                  id=""
                  className="w-full border-b-2 focus-within:outline-none py-3"
                  onChange={(e) => setBirthplace(e.target.value)}
                  disabled={submitLoading}
                  value={birthplace}
                />
              )}
            </div>
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${
                    birthdate ? "" : "text-pink-600"
                  }`}
                >
                  Tanggal Lahir
                </span>
                <span>(Format: DD-MM-YYYY)</span>
              </p>
              {loading ? (
                <SkeletonTheme>
                  <Skeleton className="w-full border-b-2 focus-within:outline-none py-3" />
                </SkeletonTheme>
              ) : (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="w-full border-b-2 focus-within:outline-none py-3"
                  onChange={(date) => setBirthdate(date)}
                  selected={birthdate}
                  disabled={submitLoading}
                />
              )}
            </div>
          </div>

          <div className="flex w-full mt-3 gap-6">
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${blood ? "" : "text-pink-600"}`}
                >
                  Golongan Darah
                </span>
              </p>
              {loading ? (
                <SkeletonTheme>
                  <Skeleton className="w-full border-b-2 focus-within:outline-none py-3" />
                </SkeletonTheme>
              ) : (
                <input
                  type="text"
                  name=""
                  id=""
                  className="w-full border-b-2 focus-within:outline-none py-3"
                  onChange={(e) => setBlood(e.target.value)}
                  disabled={submitLoading}
                  value={blood}
                />
              )}
            </div>
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${
                    occupation ? "" : "text-pink-600"
                  }`}
                >
                  Pekerjaan
                </span>
              </p>
              {loading ? (
                <SkeletonTheme>
                  <Skeleton className="w-full border-b-2 focus-within:outline-none py-3" />
                </SkeletonTheme>
              ) : (
                <input
                  type="text"
                  name=""
                  id=""
                  className="w-full border-b-2 focus-within:outline-none py-3"
                  onChange={(e) => setOccupation(e.target.value)}
                  disabled={submitLoading}
                  value={occupation}
                />
              )}
            </div>
          </div>
          <div className="flex w-full mt-3 gap-6">
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${phone ? "" : "text-pink-600"}`}
                >
                  Nomor Telepon
                </span>
              </p>
              {loading ? (
                <SkeletonTheme>
                  <Skeleton className="w-full border-b-2 focus-within:outline-none py-3" />
                </SkeletonTheme>
              ) : (
                <input
                  type="text"
                  name=""
                  id=""
                  className="w-full border-b-2 focus-within:outline-none py-3"
                  onChange={(e) => setPhone(e.target.value)}
                  disabled={submitLoading}
                  value={phone}
                />
              )}
            </div>
            <div className="w-1/2">
              <p>
                <span
                  className={`font-semibold ${email ? "" : "text-pink-600"}`}
                >
                  Email
                </span>
              </p>
              {loading ? (
                <SkeletonTheme>
                  <Skeleton className="w-full border-b-2 focus-within:outline-none py-3" />
                </SkeletonTheme>
              ) : (
                <input
                  type="text"
                  name=""
                  id=""
                  className="w-full border-b-2 focus-within:outline-none py-3"
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={submitLoading}
                  value={email}
                />
              )}
            </div>
          </div>
          <div className="mt-3">
            <p>
              <span
                className={`font-semibold ${address ? "" : "text-pink-600"}`}
              >
                Alamat
              </span>
            </p>
            {loading ? (
              <SkeletonTheme>
                <Skeleton className="w-full border-b-2 focus-within:outline-none py-3" />
              </SkeletonTheme>
            ) : (
              <textarea
                rows={4}
                className="w-full border-2 focus-within:outline-none p-1 mt-1"
                onChange={(e) => setAddress(e.target.value)}
                disabled={submitLoading}
                value={address}
              />
            )}
          </div>
          <div className="mt-3">
            {birthdate
              ? ` Tanggal lahir: ${birthdate.toLocaleDateString("id-ID", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}`
              : ""}
          </div>

          <div className="flex justify-center gap-3 mt-6 text-white text-sm font-semibold">
            {loading || submitLoading ? (
              <ClipLoader />
            ) : (
              <>
                {" "}
                <button className="bg-red-500 px-5 py-1 rounded-full">
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`px-5 py-1 rounded-full ${
                    !name ||
                    !family ||
                    !kepel ||
                    !nik ||
                    !birthdate ||
                    !birthplace ||
                    !gender ||
                    !address ||
                    !blood ||
                    !occupation ||
                    !phone ||
                    !email
                      ? "bg-slate-500"
                      : "bg-green-500"
                  }`}
                  disabled={
                    !name ||
                    !family ||
                    !kepel ||
                    !nik ||
                    !birthdate ||
                    !birthplace ||
                    !gender ||
                    !address ||
                    !blood ||
                    !occupation ||
                    !phone ||
                    !email
                  }
                >
                  Submit
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPerson;
