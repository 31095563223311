import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";

const Login = ({ notify }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      checkToken();
    }
  }, []);

  async function checkToken() {
    try {
      const response = await axios({
        method: "POST",
        url: "checktoken",
        data: {
          access_token: localStorage.getItem("access_token"),
        },
      });

      if (response.status === 200) {
        navigate("/dashboard");
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.name === "TokenExpiredError") {
        localStorage.clear();
      }
    }
  }

  async function clickLogin(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios({
        method: "POST",
        url: "login",
        data: {
          username,
          password,
        },
      });

      localStorage.setItem("access_token", response.data.access_token);
      setLoading(false);
      navigate("/dashboard");
    } catch (err) {
      console.log(err);
      setLoading(false);
      notify("Username/password salah", "error");
    }
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center p-3">
      <div className="w-full md:w-1/2 lg:w-1/4 bg-white border-2 border-slate-200 shadow-xl p-12 rounded-lg">
        <img
          src="https://d1fdloi71mui9q.cloudfront.net/dw73EeRsQzGAXL9vACRR_-1080p-%20LOGO%20BARU%20PEMUDA%20KGPM.png"
          alt="logo"
          className="h-1/4 w-1/4 mb-3 rounded-full"
        />
        <div className="font-bold w-full text-2xl">
          <p>DATABASE</p>
          <p>PEMUDA KGPM</p>
        </div>
        <div className="font-bold w-full text-2xl">
          <p className="text-red-500">LOGIN</p>
        </div>
        <form
          action="submit"
          className="w-full"
          onSubmit={(e) => clickLogin(e)}
        >
          <div className="my-5">
            <input
              type="text"
              className="w-full py-2 focus-within:outline-none border-b-2 text-sm"
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
              disabled={loading}
            />
          </div>
          <div className="my-5 relative flex">
            <input
              type={showPassword ? "text" : "password"}
              className="w-full py-2 focus-within:outline-none border-b-2 text-sm"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />
            <button
              type="button"
              className="absolute right-0 bottom-0 py-2"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "X" : <AiOutlineEye />}
            </button>
          </div>
          <div className="flex justify-center mt-5">
            <button
              disabled={!username || !password || loading}
              className={`${
                !username || !password || loading
                  ? "bg-slate-200"
                  : "bg-gradient-to-r from-sky-500 to-cyan-500 hover:bg-slate-600"
              } mx-auto w-full py-3 my-3 text-white text-xs font-semibold rounded-full `}
            >
              LOGIN
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
