import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddSidang = ({ notify }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [WilayahId, setWilayahId] = useState("");
  const [area, setArea] = useState("");
  const [bps, setBps] = useState("");
  const [pms, setPms] = useState("");
  const [kontak, setKontak] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/");
    }
  }, []);

  const arr = [];
  for (let index = 1; index <= 34; index++) {
    arr.push(index);
  }

  async function submit(e) {
    setSubmitLoading(true);
    e.preventDefault();
    try {
      await axios({
        method: "POST",
        url: "sidang",
        headers: {
          access_token: localStorage.getItem("access_token"),
        },
        data: {
          name,
          WilayahId,
          area,
          bps,
          pms,
          kontak
        },
      });
      notify("Sidang berhasil ditambahkan", "success");
      navigate("/dashboard");
    } catch (err) {
      if (err.response.data.message === 'Token expired') {
        notify("Masa login habis, silahkan login kembali", "error");
        localStorage.clear()
        navigate('/')
      }
      console.log(err);
      setSubmitLoading(false);
    }
  }

  return (
    <div className="max-w-screen lg:w-[900px] min-h-screen mx-auto">
      <div className="my-3">
        <p className="text-2xl font-bold">TAMBAH SIDANG</p>
      </div>
      <div className="w-3/4 mx-auto mt-3">
        <form
          action="submit"
          className="border-2 rounded-md px-8 py-6 shadow-md"
          onSubmit={(e) => submit(e)}
        >
          <div>
            <p>
              <span className={`font-semibold ${name ? "" : "text-pink-600"}`}>
                Nama Sidang
              </span>
              <span className="text-sm italic font-semibold">
                {" (tidak perlu menuliskan KGPM di depan)"}
              </span>
            </p>
            <input
              type="text"
              name=""
              id=""
              className="w-full border-b-2 focus-within:outline-none py-3"
              onChange={(e) => setName(e.target.value)}
              disabled={submitLoading}
            />
          </div>
          <div className="w-full flex gap-5 mt-6">
            <div className="w-1/2">
              <p
                className={`font-semibold ${WilayahId ? "" : "text-pink-600"}`}
              >
                Wilayah
              </p>
              <select
                name=""
                id=""
                className="w-full border-2 py-2 mt-2 text-center rounded"
                onChange={(e) => setWilayahId(e.target.value)}
                disabled={submitLoading}
              >
                <option value="" className="text-slate-200" selected disabled>
                  Pilih wilayah
                </option>
                {arr.map((e) => {
                  return <option value={e}>{e} </option>;
                })}
              </select>
            </div>
            <div className="w-1/2">
              <p className={`font-semibold ${area ? "" : "text-pink-600"}`}>
                Kota/Kabupaten
              </p>
              <input
                type="text"
                name=""
                id=""
                className="w-full border-b-2 focus-within:outline-none py-3"
                onChange={(e) => setArea(e.target.value)}
                disabled={submitLoading}
              />
            </div>
          </div>
          <div className="mt-6">
            <p className={`font-semibold ${bps ? "" : "text-pink-600"}`}>
              Nama Ketua BPS
            </p>
            <input
              type="text"
              name=""
              id=""
              className="w-full border-b-2 focus-within:outline-none py-3"
              onChange={(e) => setBps(e.target.value)}
              disabled={submitLoading}
            />
          </div>
          <div className="mt-6">
            <p className={`font-semibold ${pms ? "" : "text-pink-600"}`}>
              Nama Ketua PMS
            </p>
            <input
              type="text"
              name=""
              id=""
              className="w-full border-b-2 focus-within:outline-none py-3"
              onChange={(e) => setPms(e.target.value)}
              disabled={submitLoading}
            />
          </div>
          <div className="mt-6">
            <p className={`font-semibold ${kontak ? "" : "text-pink-600"}`}>
            Kontak
            </p>
            <input
              type="text"
              name=""
              id=""
              className="w-full border-b-2 focus-within:outline-none py-3"
              onChange={(e) => setKontak(e.target.value)}
              disabled={submitLoading}
            />
          </div>
          <div className="flex justify-center gap-3 mt-6 text-white text-sm font-semibold">
            <button
              className={`px-5 py-1 rounded-full ${
                submitLoading ? "bg-slate-500" : "bg-red-500 "
              }`}
              onClick={() => navigate("/dashboard")}
              disabled={submitLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={
                !name || !WilayahId || !area || !pms || !bps || submitLoading
              }
              className={`px-5 py-1 rounded-full ${
                !name || !WilayahId || !area || !pms || !bps || submitLoading
                  ? "bg-slate-500"
                  : "bg-green-500"
              }`}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSidang;
