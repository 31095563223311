import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const DetailPerson = ({notify}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [person, setPerson] = useState(null);
  const [formattedBirthdate, setFormattedBirthdate] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/");
    }
    fetch();
  }, []);

  async function fetch() {
    try {
      const response = await axios({
        method: "GET",
        url: "person/" + id,
        headers: {
          access_token: localStorage.getItem("access_token"),
        },
      });

      console.log(response);
      setPerson(response.data);

      const b = new Date(response.data.birthdate);
      const bd = b.toLocaleDateString("id-ID", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      setFormattedBirthdate(bd);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      if (err.response.data.message === 'Token expired') {
        notify("Masa login habis, silahkan login kembali", "error");
        localStorage.clear()
        navigate('/')
      }
      console.log(err);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }

  const cardSkeleton = () => {
    return (
      <>
        <SkeletonTheme baseColor="#0000" highlightColor="#ffff">
          <div className="my-3 bg-slate-100 border-2 rounded-md">
            <Skeleton />
            <Skeleton />
          </div>
          <div className="w-full mx-auto mt-3">
            <div
              action="submit"
              className="border-2 rounded-md px-8 py-6 shadow-md bg-slate-100"
            >
              <div className="flex w-full mt-3 gap-6">
                <div className="w-1/2">
                  <Skeleton /> <Skeleton />
                </div>
                <div className="w-1/2">
                  <Skeleton />
                  <Skeleton />
                </div>
              </div>
              <div className="flex w-full mt-3 gap-6">
                <div className="w-1/2">
                  <Skeleton /> <Skeleton />
                </div>
                <div className="w-1/2">
                  <Skeleton />
                  <Skeleton />
                </div>
              </div>
              <div className="flex w-full mt-3 gap-6">
                <div className="w-1/2">
                  <Skeleton /> <Skeleton />
                </div>
                <div className="w-1/2">
                  <Skeleton /> <Skeleton />
                </div>
              </div>

              <div className="flex w-full mt-3 gap-6">
                <div className="w-1/2">
                  <Skeleton /> <Skeleton />
                </div>
                <div className="w-1/2">
                  <Skeleton /> <Skeleton />
                </div>
              </div>
              <div className="flex w-full mt-3 gap-6">
                <div className="w-1/2">
                  <Skeleton /> <Skeleton />
                </div>
                <div className="w-1/2">
                  <Skeleton /> <Skeleton />
                </div>
              </div>
              <div className="mt-3">
                <Skeleton /> <Skeleton />
              </div>
              <div className="mt-3"></div>
            </div>
          </div>
          <div className="w-full flex justify-center lg:justify-end mt-3">
            <Skeleton />
          </div>
        </SkeletonTheme>
      </>
    );
  };

  const card = () => {
    return (
      <>
        <div className="my-3">
          <p className="text-2xl font-bold">
            <span className="text-red-500">KGPM {person.Sidang.name}</span>
          </p>
          <p className="text-2xl font-bold">{person.name}</p>
        </div>
        <div className="w-full mx-auto mt-3">
          <div
            action="submit"
            className="border-2 rounded-md px-8 py-6 shadow-md"
          >
            <div className="flex w-full mt-3 gap-6">
              <div className="w-1/2">
                <p>
                  <span className={`font-semibold`}>Nama Keluarga</span>
                </p>
                <p>{person.family}</p>
              </div>
              <div className="w-1/2">
                <p>
                  <span className={`font-semibold `}>Jenis Kelamin</span>
                </p>
                {person.gender === "L" ? "Laki - laki" : "Perempuan"}
              </div>
            </div>
            <div className="flex w-full mt-3 gap-6">
              <div className="w-1/2">
                <p>
                  <span className={`font-semibold`}>Kepel</span>
                </p>
                <p>{person.kepel}</p>
              </div>
              <div className="w-1/2">
                <p>
                  <span className={`font-semibold`}>NIK</span>
                </p>
                <p>{person.nik}</p>
              </div>
            </div>
            <div className="flex w-full mt-3 gap-6">
              <div className="w-1/2">
                <p>
                  <span className={`font-semibold`}>Tempat Lahir</span>
                </p>
                <p>{person.birthplace}</p>
              </div>
              <div className="w-1/2">
                <p>
                  <span className={`font-semibold`}>Tanggal Lahir</span>
                </p>
                <p>{formattedBirthdate}</p>
              </div>
            </div>

            <div className="flex w-full mt-3 gap-6">
              <div className="w-1/2">
                <p>
                  <span className={`font-semibold `}>Golongan Darah</span>
                </p>
                <p>{person.blood}</p>
              </div>
              <div className="w-1/2">
                <p>
                  <span className={`font-semibold`}>Pekerjaan</span>
                </p>
                <p>{person.occupation}</p>
              </div>
            </div>
            <div className="flex w-full mt-3 gap-6">
              <div className="w-1/2">
                <p>
                  <span className={`font-semibold`}>Nomor Telepon</span>
                </p>
                <p>{person.phone}</p>
              </div>
              <div className="w-1/2">
                <p>
                  <span className={`font-semibold`}>Email</span>
                </p>
                <p>{person.email}</p>
              </div>
            </div>
            <div className="mt-3">
              <p>
                <span className={`font-semibold`}>Alamat</span>
              </p>
              <p>{person.address}</p>
            </div>
            <div className="mt-3"></div>
          </div>
        </div>
        <div className="w-full flex justify-center lg:justify-end mt-3">
          <button
            className="border-2 block text-sm rounded-full border-slate-700 hover:bg-slate-700 hover:text-white transition-all duration-150  md:px-4 py-1 "
            onClick={() => navigate(`/person/edit/${id}`)}
          >
            Edit data
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="max-w-screen lg:w-[900px] min-h-screen mx-auto px-3 lg:px-0">
      {loading ? cardSkeleton() : card()}
    </div>
  );
};

export default DetailPerson;
