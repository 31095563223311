import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../components/Loading";
import ReactPaginate from "react-paginate";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Sidang = ({ notify }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [sidang, setSidang] = useState(null);
  const [bps, setBps] = useState(null);
  const [pms, setPms] = useState(null);
  const [kontak, setKontak] = useState(null);
  const [area, setArea] = useState(null);
  const [wilayah, setWilayah] = useState(null);
  const [count, setCount] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(localStorage.getItem('sidangLimit') || 5);
  const [firstLoading, setFirstLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/");
    }
    fetchSidang();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchSidang();
  }, [page, keyword, limit]);

  async function fetchSidang() {
    try {
      const response = await axios({
        method: "GET",
        url: `sidang/${id}?search=${keyword}&page=${page}&limit=${limit}`,
        headers: {
          access_token: localStorage.getItem("access_token"),
        },
      });

      console.log(response.data);
      setData(response.data.sidang.People);
      setCount(response.data.total);
      setSidang(response.data.sidang.name);
      setArea(response.data.sidang.area);
      setWilayah(response.data.sidang.WilayahId);
      setBps(response.data.sidang.bps);
      setPms(response.data.sidang.pms);
      setKontak(response.data.sidang.kontak);
      setPage(response.data.page);
      setPages(response.data.totalPage);

      setTimeout(() => {
        setLoading(false);
        setFirstLoading(false);
      }, 1000);
    } catch (err) {
      console.log(err);
      if (err.response.data.message === "Token expired") {
        notify("Masa login habis, silahkan login kembali", "error");
        localStorage.clear();
        navigate("/");
      }
      setTimeout(() => {
        setLoading(false);
        setFirstLoading(false);
      }, 1000);
    }
  }

  function changePage({ selected }) {
    setPage(selected);
  }

  function changeLimit(value) {
    setLimit(value);
    localStorage.setItem("sidangLimit", Number(value));
  }

  function changeKeyword(value) {
    setPage(0)
    setKeyword(value)
  }

  const cards = () => {
    return (
      <>
        {data.map((e, i) => {
          return (
            <div
              className={`w-full py-3 flex items-center border-x-2  gap-5 rounded-sm hover:bg-slate-300 hover:border-l-slate-600 cursor-pointer ${
                i % 2 !== 1 ? "bg-white" : "bg-slate-100"
              } ${i === data.length - 1 ? "border-b-2 rounded-b-lg" : ""}`}
              onClick={() => navigate("/person/" + e.id)}
            >
              <p className="w-[5%] text-center">{(page) * limit + (i+1) }</p>
              <p className="w-[5%] text-center">{e.id}</p>
              <p className="w-[15%] ">{e.name}</p>
              <p className="w-[15%] ">{e.family}</p>
              <p className="w-[5%] ">{e.kepel}</p>
              <p className="w-[5%] ">{e.gender}</p>
              <p className="w-[10%] ">
                {e.birthplace},{" "}
                {new Date(e.birthdate).toLocaleDateString("id-ID", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
              <p className={`w-[10%] text-center lg:text-start ${(e.age > 35 ) || (e.age < 16 ) ? "bg-red-500 text-white" : ''}`}>{e.age} Tahun</p>
              <p className="w-[20%]">{e.address}</p>
            </div>
          );
        })}
      </>
    );
  };

  const cardSkeleton = () => {
    return (
      <>
        <SkeletonTheme baseColor="#0000" highlightColor="#ffff">
          {Array(Number(limit))
            .fill()
            .map((e, i) => {
              return (
                <div
                  className={`w-full p-3 flex items-center border-x-2  rounded-sm animate-pulse
                    ${i % 2 !== 1 ? "bg-white" : "bg-slate-100"} ${
                    i === limit - 1 ? "border-b-2 rounded-b-lg" : ""
                  }`}
                  onClick={() => navigate("/person/" + e.id)}
                >
                  <Skeleton />
                </div>
              );
            })}
        </SkeletonTheme>
      </>
    );
  };

  if (firstLoading) {
    return <Loading showLoading={firstLoading} />;
  }

  return (
    <div className="max-w-screen w-screen  mx-auto p-3 lg:px-6 min-h-screen">
      <div className=" md:px-3 lg:px-0 md:flex justify-between items-center">
        <div>
          <p className="text-xl font-semibold">
            <span className="text-red-500 font-bold">KGPM</span> {sidang}
          </p>
          <p>
            Wilayah {wilayah}, <span className="font-semibold">{area}</span>
          </p>
          <p>
            <span className="font-semibold">Ketua BPS:</span> {bps}
          </p>
          <p>
            <span className="font-semibold">Ketua PMS:</span> {pms}
          </p>
          <p>
            <span className="font-semibold">Kontak:</span> {kontak}
          </p>
          <p>
            <span className="font-semibold">Jumlah data:</span> {count}
          </p>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center mt-3">
            <div className="flex gap-3">
              <p>Data per halaman</p>
              <select
                name=""
                id=""
                value={limit}
                onChange={(e) => changeLimit(e.target.value)}
                className="border-2 px-1 rounded-lg focus-within:outline-none"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
            </div>
          </div>
        </div>
        <div className="self-end flex flex-col gap-1 md:items-end mt-3 md:mt-0">
          <button
            className="border-2 block text-sm rounded-full border-slate-700 hover:bg-slate-700 hover:text-white transition-all duration-150  md:px-4 py-1 "
            onClick={() => navigate(`/sidang/edit/${id}`)}
          >
            Edit sidang
          </button>
          <button
            className="border-2 block text-sm rounded-full border-slate-700 hover:bg-slate-700 hover:text-white transition-all duration-150  md:px-4 py-1 "
            onClick={() => navigate(`/sidang/person/add/${id}`)}
          >
            Tambah data
          </button>
        </div>
      </div>
      <div className="w-full border-b-2 mt-3">
        <input
          type="text"
          name=""
          id=""
          className="w-full py-2 focus-within:outline-none placeholder:italic"
          placeholder="Cari id / nama"
          onChange={(e) => changeKeyword(e.target.value)}
        />
      </div>
      <div className="text-xs rounded-tl-lg rounded-tr-lg w-full gap-5 flex items-center mt-3 border-t-2 border-l-2 border-r-2 py-3 bg-slate-700 border-slate-700 shadow-sm text-white">
        <p className="w-[5%]  text-center">No</p>
        <p className="w-[5%]  text-center">ID</p>
        <p className="w-[15%]">Nama</p>
        <p className="w-[15%]">Keluarga</p>
        <p className="w-[5%]">Kepel</p>
        <p className="w-[5%]">JK</p>
        <p className="w-[10%]">TTL</p>
        <p className="w-[10%]">Umur</p>
        <p className="w-[20%] ">Alamat</p>
      </div>
      <div className="w-full text-sm">
        <div className="">{loading ? cardSkeleton() : cards()}</div>
      </div>
      <div className="flex mt-5 justify-center">
        <ReactPaginate
          previousLabel={<CgArrowLongLeft />}
          nextLabel={<CgArrowLongRight />}
          pageCount={pages}
          className="flex gap-3 items-center justify-center"
          onPageChange={changePage}
          containerClassName=""
          pageLinkClassName="border-2 p-1 rounded rounded-full px-2 hover:bg-black hover:text-white transition-all duration-150"
          activeLinkClassName="bg-black text-white"
          nextClassName="border-2 py-2 px-2 rounded hidden md:block rounded-md px-3 hover:bg-black hover:text-white transition-all duration-150"
          previousClassName="border-2 py-2 rounded hidden md:block rounded-md px-3 hover:bg-black hover:text-white transition-all duration-150"
        />
      </div>
    </div>
  );
};

export default Sidang;
