import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Sidang from "./pages/Sidang";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import AddSidang from "./pages/AddSidang";
import EditSidang from "./pages/EditSidang";
import AddPerson from "./pages/AddPerson";
import EditPerson from "./pages/EditPerson";
import DetailPerson from "./pages/DetailPerson";
import { SkeletonTheme } from "react-loading-skeleton";
import Footer from "./components/Footer";
import axios from "axios";

axios.defaults.baseURL = 'https://dbserver.pemudakgpm.com/';
// axios.defaults.baseURL = 'http://localhost:3000/';

function App() {
  function notify(value, type) {
    if (type === "success") {
      toast.success(value);
    } else {
      toast.error(value);
    }
  }

  function CenterNotification(string, icon) {
    Swal.fire({
      position: "center",
      icon: icon,
      title: string,
      showConfirmButton: false,
      timer: 1500,
    });
  }

  return (
    <div className="">
      <SkeletonTheme baseColor="#0000" highlightColor="#ffff">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          limit={1}
        />
        <Navbar />
        <Routes>
          <Route path="/dashboard" element={<Dashboard notify={notify}/>} />
          <Route path="/sidang/add" element={<AddSidang notify={notify} />} />
          <Route
            path="/sidang/edit/:id"
            element={<EditSidang notify={notify} />}
          />
          <Route path="/sidang/:id" element={<Sidang notify={notify}/>} />
          <Route path="/person/:id" element={<DetailPerson notify={notify}/>} />
          <Route
            path="/person/edit/:id"
            element={<EditPerson notify={notify} />}
          />
          <Route
            path="/sidang/person/add/:SidangId"
            element={<AddPerson notify={notify} />}
          />
          <Route path="/" element={<Login notify={notify} />} />
        </Routes>
        <Footer/>
      </SkeletonTheme>
    </div>
  );
}

export default App;
